<template>
  <div class="container">
    <div class="gobtn">
      <q-btn
        class="full-width"
        outline
        :label="$t('common.go_shopping')"
      />
    </div>
  </div>
</template>

<script>

import { golink } from '../index'

export default {
  name: 'goLook',
  props: {
    datas: Object
  },
  setup (props) {
    return {
      golink
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 50px;
  .gobtn {
    width: 334px;
    margin: 35px auto;
  }
}
</style>
